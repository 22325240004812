<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-merge-git-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">اضافه کردن دسته بندی</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <form @submit.prevent="submit">
        <div class="grid sm:grid-cols-3 gap-3">
          <MnrText v-model="name" title="نام دسته‌بندی" placeholder="یک نام وارد کنید" />
        </div>
        <div class="sm:w-60 w-full mt-3 mx-auto">
          <Button type="submit" class="mt-5 text-sm h-11 lg:text-lg" color="sky">
            ثبت
          </Button>
        </div>
      </form>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrText,
    Button,
    ErrorBox,
  },
  data() {
    return {
      group: {},
      errors: [],
      name: "",
      firstLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = [];
      $this.firstLoading = true;
      $this.$axios
        .post("/api/group-settings/" + $this.$route.params.groupId, {
          id: $this.$store.state.user.id,
          name: $this.name,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "GroupOwnerSettingBranchs",
            params: {
              id: $this.$store.state.user.id,
              groupId: $this.$route.params.groupId,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
